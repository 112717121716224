.player-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--background-color);
  border-radius: 25px;
  box-shadow: var(--shadow);
}
