.player-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 603px;
  width: 433px;
  background-color: var(--background-color);
  color: var(--font-color);
  border-radius: 25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  cursor: pointer;
  transition: 1s;
  position: relative;
}

.player-item:hover {
  transform: scale(1.03);
  box-shadow: 0px 1px 0px 0px rgba(0, 110, 255, 0.22),
    1px 0px 0px 0px rgba(0, 110, 255, 0.22),
    1px 2px 0px 0px rgba(0, 110, 255, 0.22),
    2px 1px 0px 0px rgba(0, 110, 255, 0.22),
    2px 3px 0px 0px rgba(0, 110, 255, 0.22),
    3px 2px 0px 0px rgba(0, 110, 255, 0.22),
    3px 4px 0px 0px rgba(0, 110, 255, 0.22),
    4px 3px 0px 0px rgba(0, 110, 255, 0.22),
    4px 5px 0px 0px rgba(0, 110, 255, 0.22),
    5px 4px 0px 0px rgba(0, 110, 255, 0.22),
    5px 6px 0px 0px rgba(0, 110, 255, 0.22),
    6px 5px 0px 0px rgba(0, 110, 255, 0.22),
    6px 7px 0px 0px rgba(0, 110, 255, 0.22),
    7px 6px 0px 0px rgba(0, 110, 255, 0.22),
    7px 8px 0px 0px rgba(0, 110, 255, 0.22),
    8px 7px 0px 0px rgba(0, 110, 255, 0.22);
  background-color: #80b7ff;
}
.player-item img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire area */
  position: absolute;
  top: 0;
  left: 0;

  border-radius: 50%;
  margin: 0 0 10px 0;
  transition: 3s;
}
.player-item img:hover {
  transform: scale(1.3);
}
.photo {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.player-item p {
  position: absolute;
  bottom: 10px; /* Adjust as needed to position the text */
  left: 10px; /* Adjust as needed to position the text */
  background-color: rgba(0, 0, 0, 0); /* Background color for text */
  padding: 5px;
  padding: 50px 0 auto;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
}
