.player-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  width: 90%;
  background-color: var(--background-color);
  color: var(---color);
  box-shadow: var(--shadow);
  padding: 0 20px;
  border-radius: 25px;
  margin: 20px auto;
  font-family: Arial, Helvetica, sans-serif;
}
.player {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: fit-content;
  width: 100%;
  border-radius: 25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 30px 0 auto;
  cursor: pointer;
  transition: 0.5s;
}
.back {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 30px 0 auto;
  transition: 0.5s;
}
.back button {
  --b: 3px; /* border thickness */
  --s: 0.45em; /* size of the corner */
  --color: var(---color);

  padding: calc(0.5em + var(--s)) calc(0.9em + var(--s));
  color: var(--color);
  --_p: var(--s);
  background: conic-gradient(
      from 90deg at var(--b) var(--b),
      #0000 90deg,
      var(--color) 0
    )
    var(--_p) var(--_p) / calc(100% - var(--b) - 2 * var(--_p))
    calc(100% - var(--b) - 2 * var(--_p));
  transition: 0.3s linear, color 0s, background-color 0s;
  outline: var(--b) solid #0000;
  outline-offset: 0.6em;
  font-size: 16px;
  width: 80%;
  border: 0;

  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  transition: 0.5s;
}

.back button:hover,
.back button:focus-visible {
  --_p: 0px;
  outline-color: var(--color);
  outline-offset: 0.05em;
}

.back button:active {
  background: var(--color);
  color: #fff;
}

.player-details h1 {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 30px;
}
.player-details h2 {
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 20px;
}
.player-details p {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 15px;
}

.player-details h1 span,
.player-details h2 span,
.player-details p span {
  font-weight: 700;
}
