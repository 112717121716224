@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,600;0,700;1,600;1,700&family=Rubik&display=swap");

body,
html,
.App {
  height: -webkit-fill-available;
  width: 100%;
  overflow-y: auto;
  transition: 0.5s;
}

body {
  background-color: var(--background-color1);
  position: relative;
  font-family: "Rubik", sans-serif;
  margin: 0;
}

.App {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: var(--background-color1);
}
.App.light {
  --background-color1: #eceff3;
  --background-color: #ffffff;
  ---color: black;
  --font-color: #e7ba25;
  --shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.App.dark {
  --background-color1: #161616;
  --background-color: #202020;
  ---color: white;
  --font-color: #823ddf;
  --shadow: rgba(255, 255, 255, 0.1) 0px 4px 16px,
    rgba(255, 255, 255, 0.05) 0px 8px 32px;
}
