.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 90%;
  background-color: var(--background-color);
  color: var(---color);
  box-shadow: var(--shadow);
  padding: 0 20px;
  border-radius: 25px;
  margin: 20px auto;
}

.Header button img {
  height: 40px;
  width: 40px;
}
.Header button {
  height: 60px;
  width: 60px;
  margin: 0 15px 0 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}
.Header button:hover {
  transform: scale(1.2);
  transition: 1s;
}
.title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 40px;
  font-weight: 600;
  margin-left: 44%;
}
